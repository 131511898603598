import { useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Image from 'next/image'
import logoChq from '@/images/logos/campaignhq-logo.svg'
import Link from 'next/link'
import Banner from '@/components/landing-page/Banner'
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from '@heroicons/react/20/solid'
import {
  LightningBoltIcon,
  ScaleIcon,
  SupportIcon,
} from '@heroicons/react/outline'
// import { PopoverButton } from '@/components/PopoverButton'
import { Button } from '@/components/Button'
import { Container } from '@/components/Container'
import { Logo } from '@/components/Logo'
import { NavLink } from '@/components/NavLink'
import { Fragment } from 'react'
import FeatureDropdown from './FeatureDropdown'
const navigation = [
  { name: 'Pricing', href: '/pricing' },
  { name: 'FAQs', href: '/faqs' },
  { name: 'Resources', href: '/resources' },
]

export default function Header({
  withNav = true,
  location = 'signup',
  ...props
}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <Banner />
      <nav
        className="flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">CampaignHQ</span>
            <Image
              src={logoChq}
              alt="Logo"
              unoptimized
              className="h-8 w-auto"
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        {withNav && (
          <div className="hidden lg:flex lg:gap-x-12">
            <Popover.Group className="hidden lg:flex lg:gap-x-12">
              <Popover className="relative">
                <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                  Why CampaignHQ?
                  <ChevronDownIcon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="w-md absolute -left-8 top-full z-10 mt-3 max-w-md overflow-hidden rounded-md bg-white shadow-sm ring-1 ring-gray-900/5">
                    <div className="p-4">
                      <div
                        key="about us"
                        className="group relative flex items-center gap-x-6 rounded-lg p-2 text-sm leading-6 hover:bg-gray-50"
                      >
                        <div className="flex-auto">
                          <Link
                            href="/about-us"
                            className="block font-semibold text-gray-900"
                          >
                            About us
                            <span className="absolute inset-0" />
                          </Link>
                          {/* <p className="mt-1 text-gray-600">
                          Get a better understanding of where your traffic is coming from.
                        </p> */}
                        </div>
                      </div>
                      <div
                        key="about us"
                        className="group relative flex items-center gap-x-6 rounded-lg p-2 text-sm leading-6 hover:bg-gray-50"
                      >
                        <div className="flex-auto">
                          <Link
                            href="/savings-calculator"
                            className="block font-semibold text-gray-900"
                          >
                            Savings calculator
                            <span className="absolute inset-0" />
                          </Link>
                          {/* <p className="mt-1 text-gray-600">
                          Get a better understanding of where your traffic is coming from.
                        </p> */}
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
            </Popover.Group>
            <FeatureDropdown></FeatureDropdown>
            {navigation.map((item) => (
              <Link
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </Link>
            ))}
          </div>
        )}

        {!withNav && location == 'signup' && (
          <div className="hidden items-center space-x-3 lg:flex lg:flex-1 lg:justify-end">
            <span>Already a user ?</span>
            <Link
              href="/login"
              className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm border border-indigo-50 font-semibold text-indigo-600 hover:border-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Login here
            </Link>
          </div>
        )}

        {!withNav && location == 'login' && (
          <div className="hidden items-center space-x-3 lg:flex lg:flex-1 lg:justify-end">
            <span>Don't have an account ?</span>
            <Link
              href="/signup"
              className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm border border-indigo-50 font-semibold text-indigo-600 hover:border-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign up free
            </Link>
          </div>
        )}

        {withNav && (
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {props.user ? (
              <Link
                href="/get-started"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Go to dashboard <span aria-hidden="true">&rarr;</span>
              </Link>
            ) : (
              <div className="flex items-center justify-center gap-x-6">
                <Link
                  href="/login"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Log in
                </Link>
                <Link
                  href="/signup"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign up <span aria-hidden="true">&rarr;</span>
                </Link>
              </div>
            )}
          </div>
        )}
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">CampaignHQ</span>
              <Image
                src={logoChq}
                alt="Logo"
                unoptimized
                className="h-8 w-auto"
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                {props.user ? (
                  <Link
                    href="/get-started"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Go to dashboard
                  </Link>
                ) : (
                  <div className="">
                    <Link
                      href="/login"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Log in
                    </Link>
                    <Link
                      href="/signup"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Sign up <span aria-hidden="true">&rarr;</span>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
